import { graphql, useStaticQuery } from "gatsby";
import get from "lodash/get";
import i18n from "../../../utils/i18n";

const usePreparedData = (activeCategory = 0) => {
  const partnerSectionData = useStaticQuery(graphql`
    query {
      allPrismicGlobalData {
        nodes {
          lang
          dataRaw
        }
      }
      allPrismicPartnerscollaborators {
        nodes {
          url
          lang
          data {
            title {
              text
            }
            logo {
              url
            }
            category
          }
        }
      }
    }
  `);

  // const sectionData = get(
  //   partnerSectionData,
  //   "allPrismicGlobalData.nodes[0].dataRaw",
  //   {}
  // );

  const category = activeCategory === 0 ? "Our Partners" : "Our Collaborators";

  const sectionD =
    partnerSectionData?.allPrismicGlobalData?.nodes?.find(
      (node) => node.lang === i18n.t("locale")
    ) || {};

  const sectionData = sectionD?.dataRaw || {};

  const pcD = get(
    partnerSectionData,
    "allPrismicPartnerscollaborators.nodes",
    []
  );

  const pc = pcD.filter(
    (n) => n.lang === i18n.t("locale") && n.data.category.indexOf(category) > -1
  );

  const sections = get(sectionData, "body2", []).filter(
    (item) => item.slice_type === "p_c_section"
  );

  const data = sections.map((section) => {
    return {
      title: get(section, "primary.title", ""),
      description: get(section, "primary.description", ""),
      images: pc.map((item) => ({
        image: get(item, "data.logo.url", ""),
        url: get(item, "url", ""),
      })),
    };
  });

  return {
    title: get(sectionData, "partners_section_title[0].text", ""),
    data,
  };
};

export default usePreparedData;
