import React from "react";
import * as s from "./index.module.scss";
import { PrismicRichText } from "@prismicio/react";
import get from "lodash/get";

const CommonPageDetailsSection = ({ slice = {} }) => {
  console.log("SLICE: ", slice);
  return (
    <div className={`${s.about_container} fadeIn`}>
      <div className={`${s.about_wrapper} section-center`}>
        <div className="row">
          <div className="col-lg-6">
            <PrismicRichText field={slice?.primary?.title || []} />
          </div>
          <div className="col-lg-6">
            <PrismicRichText
              className="st-two"
              field={slice?.primary?.rich_text || []}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CommonPageDetailsSection;
