// extracted by mini-css-extract-plugin
export var address = "index-module--address--9902b";
export var background_img_curve = "index-module--background_img_curve--a811c";
export var company_info = "index-module--company_info--05b81";
export var complaints = "index-module--complaints--e1a63";
export var contact = "index-module--contact--dcb55";
export var contact_info = "index-module--contact_info--860de";
export var copyrights = "index-module--copyrights--d19bf";
export var divider = "index-module--divider--c1be0";
export var footer_container = "index-module--footer_container--0f57a";
export var footer_content_container = "index-module--footer_content_container--cf6a2";
export var form_row = "index-module--form_row--f4b7b";
export var info_links = "index-module--info_links--ed039";
export var quick_links = "index-module--quick_links--1cd91";
export var socials = "index-module--socials--1f329";
export var subscribe = "index-module--subscribe--612f1";
export var subscribe_form = "index-module--subscribe_form--5c552";
export var subscribe_info = "index-module--subscribe_info--1821b";
export var thank_you_message = "index-module--thank_you_message--5a8cd";
export var thick_line = "index-module--thick_line--18f2d";
export var thin_line = "index-module--thin_line--985ab";