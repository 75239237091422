// extracted by mini-css-extract-plugin
export var animateIconOne = "index-module--animateIconOne--37547";
export var animateIconTwo = "index-module--animateIconTwo--51aed";
export var animate_icon_one = "index-module--animate_icon_one--62a5a";
export var animate_icon_two = "index-module--animate_icon_two--bd980";
export var body = "index-module--body--9114f";
export var css_container = "index-module--css_container--e699b";
export var css_modal_wrapper = "index-module--css_modal_wrapper--b0639";
export var css_popup = "index-module--css_popup--57410";
export var css_wrapper = "index-module--css_wrapper--89af9";
export var header = "index-module--header--31f6a";
export var no_content = "index-module--no_content--af304";
export var package_container = "index-module--package_container--bfb2f";
export var single_select = "index-module--single_select--1c06e";
export var title_wrapper = "index-module--title_wrapper--3b273";