import * as React from "react";
import { PrismicPreviewProvider } from "gatsby-plugin-prismic-previews";
import previewConfig from "./utils/prismic/previewConfig";
import { CookiesProvider } from "react-cookie";

import "./utils/i18n";

import "./src/styles/bootstrap/index.scss";
import "swiper/css";
import "swiper/css/pagination";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./src/fonts/HelveticaNeue/stylesheet.css";
import "./src/fonts/Avenir/avenir.css";
import "./src/styles/global.scss";
import "./src/styles/default.scss";
import "./src/styles/tamil-styles.scss";

const WrappedComponent = ({ children }) => (
  <PrismicPreviewProvider repositoryConfigs={previewConfig}>
    <CookiesProvider>{children}</CookiesProvider>
  </PrismicPreviewProvider>
);

export default WrappedComponent;
