import React, { useState } from "react";
import { FloatingLabel, Form, Modal } from "react-bootstrap";
import axios from "axios";
import * as styles from "./index.module.scss";
import Success from "../../../images/HeaderFooter/Success.png";
import Logo from "../../../images/HeaderFooter/Logo.svg";

const isDev = (value) => (process.env.NODE_ENV === "development" ? value : "");

const FooterPopup = ({ show, handleClose }) => {
  const [success, setSuccess] = useState(false);
  const [attachment, setAttachment] = useState(null);
  const [fail, setFail] = useState(false);
  const [check, setCheck] = useState(false);

  const [user, setUser] = useState({
    title: "",
    name: isDev("Mahesh"),
    email: isDev("mahesh@gmail.com"),
    phone: isDev("7147147147"),
    message: isDev("Test message"),
  });

  const [progress, setProgress] = useState(false);

  const onCloseModal = () => {
    setSuccess(false);
    setCheck(false);
    setFail(false);
    setAttachment(null);
    handleClose();
  };

  const encodeFile = async (e) => {
    if (!e.target.files || e.target.files.length === 0) {
      return;
    }
    const file = e.target.files[0];
    if (file.size > 2 * 1024 * 1024) {
      return;
    }
    setProgress(true);
    let reader = new FileReader();
    if (file) {
      await reader.readAsDataURL(file);
      reader.onload = () => {
        let base64 = reader.result.replace("data:", "").replace(/^.+,/, "");
        setAttachment(base64);
      };
    }
    setProgress(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setFail(false);

    const { name, email, phone, message } = user;

    setProgress(true);

    // careerSubmission()
    await axios
      .post("/api/write-to-us", {
        attachment,
        name,
        fields: [
          { key: "Title", value: "" },
          { key: "Name", value: name },
          { key: "Email", value: email },
          { key: "Phone", value: phone },
          { key: "Message", value: message },
        ],
      })
      .then(() => {
        if (process.env.NODE_ENV === "production") {
          setUser({
            name: "",
            email: "",
            phone: "",
            message: "",
          });
          setAttachment(null);
        }
        setSuccess(true);
        setProgress(false);
      })
      .catch((e) => {
        console.log(e);
        setFail(true);
        setInterval(() => {
          setFail(false);
        }, 6000);
      });
    setProgress(false);
  };

  return (
    <Modal
      show={show}
      onHide={() => onCloseModal()}
      className={`fpm ${success ? "fpm-sent" : ""}`}
      centered
    >
      <Modal.Header closeButton className={""}>
        <Modal.Title>
          {!success && (
            <div>
              <h3>
                <span>
                  <img src={Logo} alt="" />
                </span>
                Helpline
              </h3>
              <p className="st-one">Send Your Feedback/Complaints</p>
              <p className="bt-one">
                At InsureMe, We take all feedback seriously and use it to make
                our service the best it can be. So, if you have any thoughts,
                suggestions or concerns, please don't hesitate to reach out to
                us.
              </p>
            </div>
          )}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body
        className={""}
        onClick={() => {
          // setShowPopup(false);
        }}
      >
        {!success && (
          <Form
            action=""
            method="post"
            className="forms"
            onSubmit={(e) => {
              handleSubmit(e);
            }}
          >
            <div className={styles.form_row}>
              <FloatingLabel
                controlId="cName"
                label="Name (optional)"
                className="mb-sx-16 mb-sm-4 mb-md-2"
              >
                <Form.Control
                  type="text"
                  placeholder="Name (optional)"
                  value={user.name}
                  onChange={(e) => setUser({ ...user, name: e.target.value })}
                />
              </FloatingLabel>
              <FloatingLabel
                controlId="cMobile"
                label="Mobile number (optional)"
                className="mb-sx-16 mb-sm-4 mb-md-2"
              >
                <Form.Control
                  type="tel"
                  pattern="[0-9]{9,10}"
                  placeholder="Mobile number (optional)"
                  value={user.phone}
                  onChange={(e) => setUser({ ...user, phone: e.target.value })}
                  onInvalid={(e) =>
                    e.target.setCustomValidity(
                      "Enter a number between 9-10 (ex: 0777 117 117)"
                    )
                  }
                  onInput={(e) => e.target.setCustomValidity("")}
                />
              </FloatingLabel>
            </div>
            <div className={styles.form_row}>
              <FloatingLabel
                controlId="cEmail"
                label="Email address (optional)"
                className="mb-sx-16 mb-sm-4 mb-md-2"
              >
                <Form.Control
                  type="email"
                  placeholder="Email address (optional)"
                  value={user.email}
                  onChange={(e) => setUser({ ...user, email: e.target.value })}
                />
              </FloatingLabel>
              <FloatingLabel
                controlId="cv"
                label="Choose file (PDF max. 2mb)"
                className="mb-sx-16 mb-sm-4 mb-md-2"
              >
                <Form.Control
                  type="file"
                  placeholder="Choose file (PDF max. 2mb)"
                  // accept={"application/pdf, .doc, .docx, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, image/png, image/jpeg"}
                  accept={"application/pdf"}
                  onChange={encodeFile}
                />
              </FloatingLabel>
            </div>
            <FloatingLabel controlId="cfCMessage" label="Your message">
              <Form.Control
                as="textarea"
                placeholder="Your message"
                rows={7}
                value={user.message}
                required
                onChange={(e) => {
                  setUser({ ...user, message: e.target.value });
                }}
              />
            </FloatingLabel>
            {success && (
              <div className="form-success"> Thank you for applying. </div>
            )}
            {fail && (
              <div className="form-fail">
                {" "}
                Form submission failed. Please try again.{" "}
              </div>
            )}
            <div
              key={`consent`}
              className="mt-3 ps-4"
              onChange={(e) => {
                setCheck(e.target.checked);
              }}
            >
              <Form.Check
                type={"checkbox"}
                id={`consent`}
                label={`I understand that InsureMe may contact me for further clarifications. (Please be assured that we only use your personal information to reach out to you if necessary to get more information on the feedback/complaint submitted.)`}
                checked={check}
                onChange={(e) => {
                  setCheck(e.target.checked);
                }}
                required
              />
            </div>
            <button
              className="primary-btn-sm mt-4"
              type="submit"
              disabled={progress || success}
            >
              Send Now
            </button>
          </Form>
        )}
        {success && (
          <div className={`${styles.thank_you_message}`}>
            <h3 className="title-two">
              Thank you very much for your response.
            </h3>
            <img src={Success} alt="" />
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default FooterPopup;
