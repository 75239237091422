let personalInsuranceSlug = 'personal-insurance';
let corporateInsuranceSlug = 'corporate-insurance';

const customLangSlugs = {
  "en-us": 'en',
  "si-lk": "si",
  "ta-lk": "ta"
}

const linkResolver = (doc) => {

  switch (doc.type) {
    case 'blog_page':
      return `${getLangSlug(doc)}/blog`

    case 'blog_post':
      return `${getLangSlug(doc)}/blog/${doc.uid}`

    case 'business_insurance_page':
      return `${getLangSlug(doc)}/corporate`

    case 'home_page':
      return `${getLangSlug(doc)}/`

    case 'career':
      return `${getLangSlug(doc)}/careers/${doc.uid}`

    case 'careers_-_team_page':
      return `${getLangSlug(doc)}/careers/categories`

    case 'common_page':
      return `${getLangSlug(doc)}/${doc.uid}`

    case 'partnerscollaborators':
      return `${getLangSlug(doc)}/partners-and-collaborators/${doc.uid}`

    case 'seo_personal_insurance_page':
      return `${getLangSlug(doc)}/${doc.uid}`

    case 'seo_corporate_insurance_page':
      return `${getLangSlug(doc)}/${doc.uid}`

    case 'seo_blog_page':
      if (doc?.data?.parent_page?.type === "seo_corporate_insurance_page") {
        return `${getLangSlug(doc)}/${corporateInsuranceSlug}/${doc.uid}`;
      } else if (doc?.data?.parent_page?.type === "seo_personal_insurance_page") {
        return `${getLangSlug(doc)}/${personalInsuranceSlug}/${doc.uid}`;
      } else {
        return `${getLangSlug(doc)}/seo/${doc.uid}`;
      }

    default:
      return "/";
  }

};

module.exports = linkResolver;


const getLangSlug = (doc) => {
  if (doc.lang === 'en-us') {
    return ""
  }
  return `/${customLangSlugs[doc.lang]}`
}