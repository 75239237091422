// extracted by mini-css-extract-plugin
export var bullets = "index-module--bullets--e730c";
export var fade = "index-module--fade--59914";
export var fade_bullets = "index-module--fade_bullets--acdc9";
export var float_mascot_container = "index-module--float_mascot_container--e82a7";
export var hero_container = "index-module--hero_container--ed443";
export var hero_form_container = "index-module--hero_form_container--b1e0f";
export var hero_text = "index-module--hero_text--37e2b";
export var hero_text_form = "index-module--hero_text_form--83fb7";
export var homepage_hero = "index-module--homepage_hero--548c2";
export var installment_link = "index-module--installment_link--7a7da";
export var installment_scheme = "index-module--installment_scheme--ac90f";
export var mascot_container = "index-module--mascot_container--2ee7b";
export var mascot_video_container = "index-module--mascot_video_container--0a9e4";
export var tamil = "index-module--tamil--02060";
export var video_container = "index-module--video_container--24e5a";