// extracted by mini-css-extract-plugin
export var background_img_curve = "index-module--background_img_curve--7fed8";
export var background_img_curve_container = "index-module--background_img_curve_container--31a07";
export var brand_logo = "index-module--brand_logo--f195e";
export var close_icon = "index-module--close_icon--88081";
export var contact_info = "index-module--contact_info--5442a";
export var layover = "index-module--layover--12a8b";
export var line_container = "index-module--line_container--c299f";
export var main_flex = "index-module--main_flex--a2ce9";
export var main_nav = "index-module--main_nav--302b2";
export var nav_container = "index-module--nav_container--f5d66";
export var nav_content_container = "index-module--nav_content_container--6ec87";
export var nav_links_container_main = "index-module--nav_links_container_main--d7479";
export var nav_slider = "index-module--nav_slider--3b66f";
export var request_call_form = "index-module--request_call_form--a5aa2";
export var show_layover = "index-module--show_layover--94eb6";
export var show_nav = "index-module--show_nav--d9e94";
export var thick_line = "index-module--thick_line--563dc";
export var thin_line = "index-module--thin_line--e0847";
export var toggle_nav = "index-module--toggle_nav--c5a89";
export var toggler = "index-module--toggler--ca5b1";
export var toggler_container = "index-module--toggler_container--a83a0";
export var top_nav = "index-module--top_nav--fde5a";