import React, {useEffect, useState} from 'react'
import PopupImage from '../../../images/Popup/popup.jpg'
import PopupImageDesktop from '../../../images/Popup/popup-d.jpg'
import { Modal } from 'react-bootstrap'
import {GrClose} from 'react-icons/gr'
import './index.scss'

const Popup = ({show, onHide}) => {
  const [mobile, setMobile] = useState(false);
  
  useEffect(()=>{
    if(show && window.innerWidth < 1024){
      setMobile(true)
    }else if(!show || window.innerWidth > 1023){
      setMobile(false)
    }
  }, [show])

  return (
    <section className='session-popup'>
        <Modal
          show={mobile}
          onHide={onHide}
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          data-backdrop={"false"}
          className='mobile-session'
        >
          <Modal.Body>
            <img src={PopupImage} alt="" />
            <div className='close' onClick={onHide}>
                <GrClose/>
            </div>
          </Modal.Body>
        </Modal>
        {
          show && <div className="desktop-session section-center pt-4 mt-4">
            <div className='position-relative'>
              <img src={PopupImageDesktop} alt="" />
              <div className='close' onClick={onHide}>
                <GrClose/>
              </div>
            </div>
          </div>
        }
    </section>
  )
}
export default Popup