import React from "react";
import WrappedComponent from "./gatsby-wrap-with-providers";

export const wrapRootElement = ({ element }) => (
  <WrappedComponent>{element}</WrappedComponent>
);

export const shouldUpdateScroll = ({ pathname, prevRouterProps }) => {
  // this is to prevent page jump when query params are changed
  const prevPathname = prevRouterProps?.location?.pathname || null;
  return pathname !== prevPathname;
};
