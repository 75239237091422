import React, { useEffect } from "react";
import { PrismicRichText } from "@prismicio/react";
import get from "lodash/get";
import { Accordion } from "react-bootstrap";

import "./index.scss";

const CommonPageAccordionSection = ({ slice = {}, context }) => {
  const seoBlog = get(context, "SEO", false);

  useEffect(() => {
    const fade = document.querySelectorAll(".fadeIn");
    let options = {
      root: document.querySelector("#scrollArea"),
      rootMargin: "0px",
      threshold: 0.65,
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry, idx) => {
        if (entry.intersectionRatio > 0) {
          entry.target.style.animation = `fadeIn 0.5s forwards linear`;
        } else {
          entry.target.style.color = "none";
        }
      });
    }, options);

    fade.forEach((fade) => {
      observer.observe(fade);
    });
  }, []);
  return (
    <div className="pb-container fadeIn">
      <div
        className={`pb-wrapper section-center ${seoBlog ? "seo-width" : ""}`}
      >
        <div className="row">
          <div className={`${seoBlog ? "col-md-12" : "col-md-6"} text-wrapper`}>
            <PrismicRichText field={get(slice, "primary.title", [])} />
            <PrismicRichText field={get(slice, "primary.description", [])} />
          </div>

          <div
            className={`${
              seoBlog ? "col-md-12" : "col-md-6"
            } accordion-wrapper`}
          >
            <Accordion defaultActiveKey="0" className="faq-accordian accordion">
              {get(slice, "items", []).map(
                ({ accordion_title, accordion_description }, idx) => {
                  return (
                    <Accordion.Item eventKey={idx} key={idx}>
                      <Accordion.Header className="st-two">
                        <div>
                          <PrismicRichText field={accordion_title} />
                        </div>
                      </Accordion.Header>
                      <Accordion.Body>
                        <div>
                          <PrismicRichText field={accordion_description} />
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                  );
                }
              )}
            </Accordion>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CommonPageAccordionSection;
