exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-blogs-jsx": () => import("./../../../src/pages/blogs.jsx" /* webpackChunkName: "component---src-pages-blogs-jsx" */),
  "component---src-templates-blog-page-index-jsx": () => import("./../../../src/templates/BlogPage/index.jsx" /* webpackChunkName: "component---src-templates-blog-page-index-jsx" */),
  "component---src-templates-blog-post-index-jsx": () => import("./../../../src/templates/BlogPost/index.jsx" /* webpackChunkName: "component---src-templates-blog-post-index-jsx" */),
  "component---src-templates-career-index-jsx": () => import("./../../../src/templates/Career/index.jsx" /* webpackChunkName: "component---src-templates-career-index-jsx" */),
  "component---src-templates-career-team-page-index-jsx": () => import("./../../../src/templates/CareerTeamPage/index.jsx" /* webpackChunkName: "component---src-templates-career-team-page-index-jsx" */),
  "component---src-templates-common-page-index-jsx": () => import("./../../../src/templates/CommonPage/index.jsx" /* webpackChunkName: "component---src-templates-common-page-index-jsx" */),
  "component---src-templates-corporate-index-jsx": () => import("./../../../src/templates/Corporate/index.jsx" /* webpackChunkName: "component---src-templates-corporate-index-jsx" */),
  "component---src-templates-home-page-index-jsx": () => import("./../../../src/templates/HomePage/index.jsx" /* webpackChunkName: "component---src-templates-home-page-index-jsx" */),
  "component---src-templates-partners-and-collaborators-page-index-jsx": () => import("./../../../src/templates/PartnersAndCollaboratorsPage/index.jsx" /* webpackChunkName: "component---src-templates-partners-and-collaborators-page-index-jsx" */),
  "component---src-templates-seo-blog-index-jsx": () => import("./../../../src/templates/SEOBlog/index.jsx" /* webpackChunkName: "component---src-templates-seo-blog-index-jsx" */),
  "component---src-templates-seo-corporate-insurance-index-jsx": () => import("./../../../src/templates/SEOCorporateInsurance/index.jsx" /* webpackChunkName: "component---src-templates-seo-corporate-insurance-index-jsx" */),
  "component---src-templates-seo-personal-insurance-index-jsx": () => import("./../../../src/templates/SEOPersonalInsurance/index.jsx" /* webpackChunkName: "component---src-templates-seo-personal-insurance-index-jsx" */)
}

